// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyA59SDQ_SLShoHJ2sTBt1C1e35qWQFpltc",
    authDomain: "segurvending-e2b83.firebaseapp.com",
    databaseURL: "https://segurvending-e2b83.firebaseio.com",
    projectId: "segurvending-e2b83",
    storageBucket: "segurvending-e2b83.appspot.com",
    messagingSenderId: "658526660152",
    appId: "1:658526660152:web:3746e2829c4779e8"
  },
  'ins-salud.com': "UA-168051473-1"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
