import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../shared/service/global.service';

@Component({
  selector: 'app-bluecross-services',
  templateUrl: './bluecross-services.component.html',
  styleUrls: ['./bluecross-services.component.scss']
})
export class BluecrossServicesComponent implements OnInit {

  lang =  "";
  constructor(public globalService: GlobalService) { }

  ngOnInit() {
    this.lang = this.globalService.getTranslate();
  }
  services = [
    {
      img: "assets/images/app_landing1/icons/1-1.png",
      title: "Powerful System",
      description: "There are many variations of passages of Lorem Ipsum available, but the ma- jority have suffered."
    },
    {
      img: "assets/images/app_landing1/icons/1-2.png",
      title: "Personalization",
      description: "There are many variations of passages of Lorem Ipsum available, but the ma- jority have suffered."
    },
    {
      img: "assets/images/app_landing1/icons/1-3.png",
      title: "Ease of Access",
      description: "There are many variations of passages of Lorem Ipsum available, but the ma- jority have suffered."
    },
  ]

}
