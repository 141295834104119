import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../shared/service/global.service';

@Component({
  selector: 'app-bluecross-subscribe',
  templateUrl: './bluecross-subscribe.component.html',
  styleUrls: ['./bluecross-subscribe.component.scss']
})
export class BluecrossSubscribeComponent implements OnInit {

  lang =  "";
  constructor(public globalService: GlobalService) { }

  ngOnInit() {
    this.lang = this.globalService.getTranslate();
  }

}
