import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-turista-footer',
  templateUrl: './turista-footer.component.html',
  styleUrls: ['./turista-footer.component.scss']
})
export class TuristaFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
