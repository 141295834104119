import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../shared/service/global.service';

@Component({
  selector: 'app-turista-video',
  templateUrl: './turista-video.component.html',
  styleUrls: ['./turista-video.component.scss']
})
export class TuristaVideoComponent implements OnInit {

  lang =  "";
  constructor(public globalService: GlobalService, private modalService: NgbModal) { }

  ngOnInit() {
    this.lang = this.globalService.getTranslate();
  }
  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true, size: 'lg' });
  }
}
