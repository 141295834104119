import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ColorScssService } from '../../shared/service/color-scss.service';
import { GlobalService } from '../../shared/service/global.service';

@Component({
  selector: 'app-turista',
  templateUrl: './turista.component.html',
  styleUrls: ['./turista.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TuristaComponent implements OnInit {
  route2:any;
  constructor(private route: ActivatedRoute,private router: Router,public globalService: GlobalService,
    private title: Title,
    public colorPicker: ColorScssService) { }

  ngOnInit() {
    this.route2 = this.router.url.replace("/", "");
    this.colorPicker.setColorScheme('color-turista');
    this.globalService.setVIP(this.route2);
    this.title.setTitle(this.route.snapshot.data['title']);
  }

}
