import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';


@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(
    private firestore: AngularFirestore
  ) {

  }

  public create(json, collection) {
    return this.firestore.collection(collection).add(json);
  }
  public getReference(reference) {
    return this.firestore.doc(reference).ref;
  }
  public getId(collection, id) {
    return this.firestore.collection(collection).doc(id).snapshotChanges();
  }
  
  public getFilter(collection, field, criterio, value) {
    return this.firestore.collection(collection, ref => ref.where(field, criterio, value)).snapshotChanges();
  }

  public getFilterByOneOption(collection, field, value) {

    return this.firestore.collection(collection, ref => ref.where(field, "==", value)).snapshotChanges();
  }

  public getAll(collection) {
    return this.firestore.collection(collection).snapshotChanges();
  }

  public getFilterByCompuest(collection, json) {

    let fn: string;
    fn = "return firestore.collection('" + collection + "', ref => ref";
    for (let item in json) {
      fn += ".where('" + json[item].fieldToFilter + "','" + json[item].condition + "','" + json[item].valueToFilter + "')";
    }
    fn += ").snapshotChanges();";
    var func = new Function('firestore', fn);
    return func(this.firestore);
  }

  public getNotifications(collection, json) {
    return this.firestore.collection(collection, ref => ref.where(json[0].fieldToFilter, json[0].condition, json[0].valueToFilter)).snapshotChanges();
  }

  public getAllLimitOrder(collection, limit, order, type) {
    return this.firestore.collection(collection, ref =>
      ref.orderBy(order, type)
        .limit(limit)).snapshotChanges();
  }
  public getAllOrder(colection, order, type) {
    return this.firestore.collection(colection, ref =>
      ref.orderBy(order, type)).snapshotChanges();
  }
  public update(colection, id, json) {
    return this.firestore.collection(colection).doc(id).update(json);
  }
  public delete(collection, id) {
    return this.firestore.collection(collection).doc(id).delete();
  }

}
