import { Injectable, isDevMode } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment as environment1 } from '../../../environments/environment';
import { environment as environment2 } from '../../../environments/environment.prod';
@Injectable()
export class GlobalService {

    constructor(private http: HttpClient) {
        localStorage.setItem('url', '');
        localStorage.setItem('url', 'https://segurvending.com/pos/');
    }
    public getUsername() {
        return localStorage.getItem('username');
    }
    public getPassword() {
        return localStorage.getItem('password');
    }
    public setUsername(username) {
        return localStorage.setItem('username', username);
    }
    public setPassword(password) {
        return localStorage.setItem('password', password);
    }
    public getUrl() {
        return localStorage.getItem('url');
    }
    public getAgente() {
        return localStorage.getItem('agente');
    }
    public setAgente(agente) {
        return localStorage.setItem('agente', agente);
    }
    public getTranslate() {
        return localStorage.getItem('translate');
    }
    public setTranslate(translate) {
        return localStorage.setItem('translate', translate);
    }
    public setVIP(vip) {
        return localStorage.setItem('vip', vip);
    }
    public getVIP() {
        return localStorage.getItem('vip');
    }
    httpServicesResponse(data, service) {
        let url = this.getUrl() + service;
        let headers: any;
        headers = new HttpHeaders();
        headers = headers.append('Access-Control-Allow-Origin', "*");
        headers = headers.append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,OPTIONS');
        headers = headers.append('Access-Control-Allow-Headers', '*');
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(url, JSON.stringify(data), { headers: headers });
    }
}