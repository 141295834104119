import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnterpriceComponent } from './enterprice/enterprice.component';
import { TuristaComponent } from './turista/turista.component';
import { BluecrossComponent } from './bluecross/bluecross.component';

const routes: Routes = [  {
  path: '',
  children: [
      {
        path: 'sjo',
        component: EnterpriceComponent,
        data: {
          title: "Salud | Unice Landing Page"
        }
      }, 
      {
        path: 'saludins',
        component: EnterpriceComponent,
        data: {
          title: "Salud | Unice Landing Page"
        }
      }, 
      {
        path: 'visit',
        component: TuristaComponent,
        data: {
          title: "Turista | Unice Landing Page"
        }
      },  
      {
        path: 'blue',
        component: BluecrossComponent,
        data: {
          title: "Turista"
        }
      },
      {
        path: '',
        component: TuristaComponent,
        data: {
          title: "Turista | Unice Landing Page"
        }
      }, 

    ]
}
  
]


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class LayoutsRoutingModule { }
