import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-enterprice-nav',
  templateUrl: './enterprice-nav.component.html',
  styleUrls: ['./enterprice-nav.component.scss']
})
export class enterpriceNavComponent implements OnInit {

  constructor() { 

  }

  ngOnInit() {
  }
  scroll() {
    document.querySelector('#contacto').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
