import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module'
import { CountToModule } from 'angular-count-to';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { AngularTiltModule } from 'angular-tilt';
import 'hammerjs';
import 'mousetrap';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { MasonryGalleryModule } from 'ngx-masonry-gallery';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPayPalModule } from 'ngx-paypal';
import { Ng5SliderModule } from 'ng5-slider';

//Enterprice Layouts
import { EnterpriceComponent } from './enterprice/enterprice.component';
import { enterpriceNavComponent } from './enterprice/enterprice-nav/enterprice-nav.component';
import { enterpriceHeaderComponent } from './enterprice/enterprice-header/enterprice-header.component';
import { enterpriceServicesComponent } from './enterprice/enterprice-services/enterprice-services.component';
import { enterpriceAboutComponent } from './enterprice/enterprice-about/enterprice-about.component';
import { enterpriceDownloadComponent } from './enterprice/enterprice-download/enterprice-download.component';
import { enterpriceScreenshotsComponent } from './enterprice/enterprice-screenshots/enterprice-screenshots.component';
import { enterpricePricingComponent } from './enterprice/enterprice-pricing/enterprice-pricing.component';
import { enterpriceVideoComponent } from './enterprice/enterprice-video/enterprice-video.component';
import { enterpriceSubscribeComponent } from './enterprice/enterprice-subscribe/enterprice-subscribe.component';
import { enterpriceFooterComponent } from './enterprice/enterprice-footer/enterprice-footer.component';


//Turista Layouts
import { TuristaComponent } from './turista/turista.component';
import { TuristaNavComponent } from './turista/turista-nav/turista-nav.component';
import { TuristaHeaderComponent } from './turista/turista-header/turista-header.component';
import { TuristaServicesComponent } from './turista/turista-services/turista-services.component';
import { TuristaAboutComponent } from './turista/turista-about/turista-about.component';
import { TuristaDownloadComponent } from './turista/turista-download/turista-download.component';
import { TuristaScreenshotsComponent } from './turista/turista-screenshots/turista-screenshots.component';
import { TuristaPricingComponent } from './turista/turista-pricing/turista-pricing.component';
import { TuristaVideoComponent } from './turista/turista-video/turista-video.component';
import { TuristaSubscribeComponent } from './turista/turista-subscribe/turista-subscribe.component';
import { TuristaFooterComponent} from './turista/turista-footer/turista-footer.component';

//Bluecross Layouts
import { BluecrossComponent } from './bluecross/bluecross.component';
import { BluecrossNavComponent } from './bluecross/bluecross-nav/bluecross-nav.component';
import { BluecrossDownloadComponent } from './bluecross/bluecross-download/bluecross-download.component';
import { BluecrossHeaderComponent } from './bluecross/bluecross-header/bluecross-header.component';
import { BluecrossServicesComponent } from './bluecross/bluecross-services/bluecross-services.component';
import { BluecrossPricingComponent } from './bluecross/bluecross-pricing/bluecross-pricing.component';
import { BluecrossVideoComponent } from './bluecross/bluecross-video/bluecross-video.component';
import { BluecrossSubscribeComponent } from './bluecross/bluecross-subscribe/bluecross-subscribe.component';

import { NgxMasonryModule } from 'ngx-masonry';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {};

@NgModule({
  declarations: [
    EnterpriceComponent, enterpriceHeaderComponent, enterpriceNavComponent, enterpriceServicesComponent, enterpriceAboutComponent, enterpriceDownloadComponent, enterpriceScreenshotsComponent, enterpricePricingComponent, enterpriceVideoComponent, enterpriceSubscribeComponent, enterpriceFooterComponent,
    TuristaComponent,TuristaDownloadComponent,TuristaFooterComponent,TuristaHeaderComponent,TuristaNavComponent,TuristaServicesComponent,TuristaAboutComponent,TuristaScreenshotsComponent,TuristaPricingComponent,TuristaVideoComponent,TuristaSubscribeComponent,
    BluecrossVideoComponent,BluecrossSubscribeComponent,BluecrossServicesComponent,BluecrossDownloadComponent,BluecrossHeaderComponent, BluecrossNavComponent, BluecrossComponent, BluecrossPricingComponent],

  imports: [
    CommonModule,
    LayoutsRoutingModule,
    SwiperModule,
    CarouselModule,
    NgbModule,
    GalleryModule.forRoot(),
    SharedModule,
    CountToModule,
    AngularTiltModule,
    ScrollToModule.forRoot(),
    MasonryGalleryModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPayPalModule,
    Ng5SliderModule,
    NgxMasonryModule
  ],

  exports: [
   ],

  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})

export class LayoutsModule { }
