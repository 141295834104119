import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { SharedModule } from './shared/shared.module';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { environment } from '../environments/environment';
import { LandingComponent } from './landing/landing.component';
import { BlogModule } from './blog/blog.module';
import { LayoutsModule } from './layouts/layouts.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { IgxRadioModule } from 'igniteui-angular';
import { MatStepperModule } from '@angular/material/stepper';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatCardModule} from '@angular/material/card';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const cookieConfig: NgcCookieConsentConfig = {
	cookie: {
		domain: 'www.popularseguroestudiantil.com' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
	},
	palette: {
		popup: {
			background: '#000'
		},
		button: {
			background: '#f1d600'
		}
	},
	theme: 'edgeless',
	type: 'opt-out'
};
  const parsedUrl = new URL(window.location.href);
  const baseUrl = parsedUrl.origin.replace("www.", "").replace("https://", "").replace("http://", "");
  const tracking = environment[baseUrl];
  localStorage.setItem('trazabilidad',window.location.href);

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    LayoutsModule,
    BlogModule,
    MatCardModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CarouselModule,
    NgbModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
		IgxRadioModule,
    ScrollToModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    MatStepperModule,
    SharedModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    RouterModule.forRoot(routes, { useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' }),
    BsDatepickerModule.forRoot()  ,
    NgxGoogleAnalyticsModule.forRoot(environment[baseUrl])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
