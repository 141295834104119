import { Component, OnInit, Inject, OnDestroy, ɵConsole } from '@angular/core';
import { NavService, Menu } from '../shared/service/nav.service';
import { ColorScssService } from '../shared/service/color-scss.service';
import { FirestoreService } from '../shared/service/firestore.service';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs/Subscription';
import { GlobalService } from '../shared/service/global.service';
import { NgxSpinnerService } from "ngx-spinner";

import { filter, pairwise } from 'rxjs/operators';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public allMenuItems: Menu[];
  public firstHalfMenu: Menu[];
  public secondHalfMenu: Menu[];
  public index: number;
  math = Math;
  domain;
  slides = [{img:""}];
  products = [];
  prices;
  logo;
  description;
  email;
  phone;
  whatsapp;
  information = "";
  agente;
  route2 = "";
  pdf: any = "";
  today: any = Date.now();
  isMobileResolution = true;  


  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;


  constructor(
    private firestoreService: FirestoreService,
    public navServices: NavService,
    public colorPicker: ColorScssService,
    private router: Router,
    public globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private ccService: NgcCookieConsentService,
    private dom: DomSanitizer,
    @Inject(DOCUMENT) private document: any) {

    }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.globalService.setAgente(params.canal);
    });

    this.route2 = this.router.url.replace("/", "");
    console.log(this.route2);
    this.globalService.setVIP(this.route2);
    this.domain = this.document.location.hostname;
    var str = "www.";
    this.domain = this.domain.replace(str, "");
    if(this.domain=='popularseguropersonales.com' || this.domain=='popularsegurospersonales.com'){
      document.location.href = 'https://ins-personales.com';
    }
    if(this.domain=='ins-salud.com'){
      document.location.href = 'https://www.ins-salud.com/saludins';
    }
    if(this.domain=='ins-viajero.com'){
      document.location.href = 'https://www.ins-viajero.com/viajero';
    }
    if(this.domain=='visitcostaricacr.com'){
      document.location.href = 'https://www.visitcostaricacr.com/visit';
    }
    if(this.domain=='bluecrossblueshieldcr.com'){
      document.location.href = 'https://www.bluecrossblueshieldcr.com/blue';
    }
    this.firestoreService.getFilter('Store', 'dominio', '==', this.domain)
      .subscribe(data => {
        let result: any = data[0].payload.doc.data();
        this.colorPicker.setColorScheme(result.color);
        this.slides = result.slides;
        this.products = result.products;
        this.prices = result.prices;
        this.logo = result.logo;
        this.description = result.description;
        this.email = result.email;
        this.phone = result.phone;
        this.information = result.information;
        this.whatsapp = result.whatsapp;
        if(result.urlTerms != ""){
          this.pdf = this.dom.bypassSecurityTrustResourceUrl(result.urlTerms);
        }else{

        }
        this.pdf = result.urlTerms;
        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = "Cookies y privacidad";
        this.ccService.getConfig().content.message = result.cookies;
        this.ccService.getConfig().content.dismiss = "Cancelar";
        this.ccService.getConfig().content.allow = "Aceptar";
        this.ccService.getConfig().content.deny = "Denegar";
        this.ccService.getConfig().content.link = "";
        this.ccService.getConfig().content.policy = "";
        this.ccService.destroy();//remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }
  go(item) {
    this.router.navigate([item.product]);
  }
  headercarousel = [
    { 
      tagLine1:"build your",
      tagLine2:"body more strong",
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."    
    },
    { 
      tagLine1:"build your",
      tagLine2:"body more strong",
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."    
    },  
  ]
  
  headercarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
  }
  yogaCarouselOptions = {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: true,
    navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    dots: false
  }


  pricing7 = [
    {
      img: "assets/images/yoga-img/abs-yoga.png",
      package: "free",
      feature1: "100 MB Disk Space",
      feature2: "2 Subdomains",
      feature3: "5 Email Accounts",
      feature4: "Webmail Support",
      price: "0",
      btn: "More"
    },
    {
      img: "assets/images/yoga-img/abs-yoga.png",
      package: "Mediam",
      feature1: "100 MB Disk Space",
      feature2: "2 Subdomains",
      feature3: "5 Email Accounts",
      feature4: "Webmail Support",
      price: "49",
      btn: "more"
    },
    {
      img: "assets/images/yoga-img/abs-yoga.png",
      package: "Business",
      feature1: "100 MB Disk Space",
      feature2: "2 Subdomains",
      feature3: "5 Email Accounts",
      feature4: "Webmail Support",
      price: "99",
      btn: "more"
    }
  ]

  pricingcarouselOptions7 = {
    items: 3,
    nav: false,
    dots: true,
    autoplay: false,
    center: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 1
      },
      992: {
        items: 3
      }
    }
  }
  centerdSlide = [
    {
      img: 'img1',
      title: "exclusive portfolio",
      year: "2019",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
    },
    {
      img: 'img2',
      title: "exclusive portfolio",
      year: "2019",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
    },
    {
      img: 'img3',
      title: "exclusive portfolio",
      year: "2019",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
    },
    {
      img: 'img4',
      title: "exclusive portfolio",
      year: "2019",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
    },
    {
      img: 'img5',
      title: "exclusive portfolio",
      year: "2019",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
    }
  ]
  centerdslideOption = {
    items: 3,
    nav: false,
    margin: 0,
    navText: ['<img src="assets/images/music/gallery/gallery-icon/left.png">', '<img src="assets/images/music/gallery/gallery-icon/right.png">'],
    autoplay: false,
    center: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    dots: false,
    loop: true,
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      320: {
        items: 1,
        margin: 10
      },
      460: {
        items: 2
      },
      480: {
        items: 3
      },
      576: {
        items: 3
      },
      1000: {
        items: 3
      }
    }
  }
}
