import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-pricing',
  templateUrl: './enterprice-pricing.component.html',
  styleUrls: ['./enterprice-pricing.component.scss']
})
export class enterpricePricingComponent implements OnInit {
  titulo = 1;

  constructor() { }

  ngOnInit() {
  }

  change(value){
    this.titulo = value;
  }

}
