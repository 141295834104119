import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { BlogComponent } from './blog/blog.component';
export const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: 'landing',
    component: LandingComponent
  },
  {
    path: 'vip',
    component: LandingComponent
  },
  {
    path: 'fillngo',
    component: LandingComponent
  },
  {
    path: 'puerta10',
    component: LandingComponent
  },
  {
    path: 'sjo',
    component: LandingComponent
  },
  {
    path: 'sjo',
    component: LandingComponent
  },
  {
    path: 'fng01',
    component: LandingComponent
  },
  {
    path: 'fng02',
    component: LandingComponent
  },
  {
    path: 'fng03',
    component: LandingComponent
  },
  {
    path: 'fng04',
    component: LandingComponent
  },
  {
    path: 'fng05',
    component: LandingComponent
  },
  {
    path: 'fng06',
    component: LandingComponent
  },
  {
    path: 'fng07',
    component: LandingComponent
  },
  {
    path: 'fng08',
    component: LandingComponent
  },
  {
    path: 'fng09',
    component: LandingComponent
  },
  {
    path: 'fng010',
    component: LandingComponent
  },
  {
    path: 'vidasjo',
    component: LandingComponent
  },
  {
    path: 'sv03',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'pc01',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'cr',
    component: LandingComponent
  },
  {
    path: 'sv01',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'transnica',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'ticabus',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: '',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'pasesalud',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'seguroviajero',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'centroamerica',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'suiza',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'ue',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'am',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'copa',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: '506',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'acav',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'united',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'southwest',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'ba',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'af',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'klm',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'spirit',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'avianca',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },

  {
    path: 'mch',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'ac',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'alaska',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'skycap',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'travelinsurance',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: 'michelledematheu',
    loadChildren: './layouts/layouts.module#LayoutsModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: '',
    loadChildren: './form/form.module#FormModule',
    data: {
      breadcrumb: "Formulario"
    }
  },
  {
    path: '**',
    redirectTo: 'landing'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    useHash: true,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
