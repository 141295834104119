import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { RouterModule } from '@angular/router';
import { CenterMenuComponent } from './components/navigation/center-menu/center-menu.component';
import { ToastrModule } from 'ngx-toastr';
import { LoaderComponent } from './components/loader/loader.component';
import { GlobalService } from "./service/global.service";

@NgModule({
  declarations: [TapToTopComponent, CenterMenuComponent, LoaderComponent],
  exports: [
    LoaderComponent,
    CenterMenuComponent,
    TapToTopComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ToastrModule.forRoot()
  ],
  providers: [
      GlobalService
  ]
})
export class SharedModule { }
