import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../shared/service/global.service';

@Component({
  selector: 'app-turista-about',
  templateUrl: './turista-about.component.html',
  styleUrls: ['./turista-about.component.scss']
})
export class TuristaAboutComponent implements OnInit {

  lang =  "";
  constructor(public globalService: GlobalService) { }

  ngOnInit() {
    this.lang = this.globalService.getTranslate();
  }

  testimonialcarousel3 = [
    { img: "assets/images/app_landing2/team/1.png",
      name: "Sleyter Angulo",
      designation: "Art Dirrector",
      description: "Fue una experiencia muy buena, se encargaron de conseguir todas las citas médicas con las mejores opciones"
    },
    {
      img: "assets/images/app_landing2/team/2.png",
      name: "Fabián Soto",
      designation: "Art Dirrector",
      description: "“Muy satisfecho con la atención brindada, tuve un accidente en bicicleta, mi pierna derecha estaba bien golpeada, realicé mi cita y al llegar a la clínica que me correspondía me atendieron muy bien, la doctora que me atendió me dio un buen diagnóstico. También ya hice uso del seguro para mi mascota, llamé al call center y nos hicieron una cita con un veterinario muy cerca de la casa, al siguiente día mi mascota ya estaba recibiendo atención."
    },
    { 
      img: "assets/images/app_landing2/team/3.png",
      name: "Adriana Cruz",
      designation: "Art Dirrector",
      description: "Mi hija de 19 años amaneció con su piel alrededor de su ojo izquierdo inflamado. Llamamos a Teledoctor por video llamada y nos atendió una doctora muy buena gente, que realizó una serie de preguntas y le revisó el ojito. Ella le mandó los medicamentos y una crema. A los tres días se transcurso del proceso para ver como siguió mi hija."
    }
  ]
  
  teamcarouselOptions3= {
    items: 5,
    margin: 30,
    autoHeight: true,
    nav: false,
    dots:true,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 2,
            margin: 10
        },
        991: {
            items: 3,
            margin: 10
        }
    }
  }
}
