import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../shared/service/global.service';

@Component({
  selector: 'app-turista-pricing',
  templateUrl: './turista-pricing.component.html',
  styleUrls: ['./turista-pricing.component.scss']
})
export class TuristaPricingComponent implements OnInit {
  titulo = 1;
  lang =  "";
  constructor(public globalService: GlobalService) { }

  ngOnInit() {
    this.lang = this.globalService.getTranslate();
  }

  change(value){
    this.titulo = value;
  }

}
