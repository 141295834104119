import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../../shared/service/global.service';

@Component({
  selector: 'app-turista-header',
  templateUrl: './turista-header.component.html',
  styleUrls: ['./turista-header.component.scss']
})
export class TuristaHeaderComponent implements OnInit {

  lang =  "";
  constructor(public globalService: GlobalService,private modalService: NgbModal) { }

  ngOnInit() {
    this.lang = this.globalService.getTranslate();
  }
  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true, size: 'lg' });
  }
  scroll() {
    document.querySelector('#plan').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
