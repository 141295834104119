import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../shared/service/global.service';

@Component({
  selector: 'app-turista-nav',
  templateUrl: './turista-nav.component.html',
  styleUrls: ['./turista-nav.component.scss']
})
export class TuristaNavComponent implements OnInit {
  public activeLang = 'English';

  langs = ["English","Español","Deutch","Français"];

  constructor(public globalService: GlobalService) { 
    this.activeLang = this.globalService.getTranslate();
    if(this.activeLang==undefined){
      this.activeLang = 'English';
    }
  }

  ngOnInit() {
    this.globalService.setTranslate(this.activeLang);
  }
  scroll() {
    document.querySelector('#contacto').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  public cambiarLenguaje(lang) {
    this.globalService.setTranslate(lang);
    window.location.reload();    
  }
  
}
