import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../shared/service/global.service';

@Component({
  selector: 'app-turista-subscribe',
  templateUrl: './turista-subscribe.component.html',
  styleUrls: ['./turista-subscribe.component.scss']
})
export class TuristaSubscribeComponent implements OnInit {

  lang =  "";
  constructor(public globalService: GlobalService) { }

  ngOnInit() {
    this.lang = this.globalService.getTranslate();
  }

}
